export enum Age {
  "0m6m" = 0, // De 0 meses até 6 meses
  "6m2a" = 1, //De 6 meses até menos de 2 anos
  "2a5a" = 2, // De 2 anos até menos de 5 anos
  "0m5a" = 3, // De 0 meses até menos de 5 anos
  "5a10a" = 4, // De 5 anos até menos de 10 anos
  "10a19a" = 5, // De 10 meses até 19 anos"
  "0a19a" = 6, // De 0 meses até 19 anos
  "0a10a" = 7 // De 0 meses até 19 anos
}

export enum Gender {
  "all" = 0, // Todos
  "m" = 1, // Meninos
  "f" = 2 // Meninas
}

export enum Skin {
  "all" = 0, // Todos
  "black" = 1, // Pretos
  "brown" = 2, // Pardos
  "white" = 3, // Brancos
  "indian" = 4, // Indígenas
  "yellow" = 5 // Amarelos
}

export enum NutritionClassID {
  "underweight0" = 0, // Magreza Acentuada
  "underweight1" = 1, // Magreza
  "normal" = 2, // Eutrofia
  "overweight0" = 3, // Risco de Sobrepeso
  "overweight1" = 4, // Sobrepeso
  "overweight2" = 5, // Obesidade
  "overweightAggregated" = 6
  // "undewightAggregated" = 7
}

export enum NutritionFoodID {
  // 0 meses a 6 meses:
  "0m6m_1" = 1, // Aleitamento Materno Exclusivo

  // 6 meses a 2  anos:
  "6m2a_1" = 2, // Aleitamento Materno Exclusivo
  "6m2a_2" = 3, // Diversidade Alimentar Mínima
  "6m2a_3" = 4, // Frequência Mínima e Consistência Adequada
  "6m2a_4" = 5, // Consumo de Alimentos Ricos em Ferro
  "6m2a_5" = 6, // Consumo de Alimentos Ricos em Vitamina A
  "6m2a_6" = 7, // Consumo de Alimentos Ultraprocessados
  "6m2a_7" = 8, // Consumo de Hambúrguer e/ou Embutidos
  "6m2a_8" = 9, // Consumo de Bebidas Adoçadas
  "6m2a_9" = 10, // Consumo de Macarrão Instantâneo, Salgadinhos de Pacote ou Biscoitos Salgados
  "6m2a_10" = 11, // Consumo de Biscoito Recheado, Doces ou Guloseimas

  // 2 anos a 5 anos:
  "2a5a_1" = 12, // Hábito de realizar no mínimo as três refeições principais do dia
  "2a5a_2" = 13, // Hábito de realizar as refeições assistindo à televisão
  "2a5a_3" = 14, // Consumo de feijão
  "2a5a_4" = 15, // Consumo de fruta
  "2a5a_5" = 16, // Consumo de verduras e legumes
  "2a5a_6" = 17, // Consumo de hambúrguer e/ou embutidos
  "2a5a_7" = 18, // Consumo de bebidas adoçadas
  "2a5a_8" = 19, // Consumo de macarrão instantâneo, salgadinho de pacote ou biscoito salgado
  "2a5a_9" = 20, // Consumo de biscoito recheado, doces ou guloseimas
  "2a5a_10" = 21, // Consumo de Alimentos Ultraprocessados

  // 5 anos a 10 anos:
  "5a10a_1" = 22, // Hábito de realizar no mínimo as três refeições principais do dia
  "5a10a_2" = 23, // Hábito de realizar as refeições assistindo à televisão
  "5a10a_3" = 24, // Consumo de feijão
  "5a10a_4" = 25, // Consumo de fruta
  "5a10a_5" = 26, // Consumo de verduras e legumes
  "5a10a_6" = 27, // Consumo de hambúrguer e/ou embutidos
  "5a10a_7" = 28, // Consumo de bebidas adoçadas
  "5a10a_8" = 29, // Consumo de macarrão instantâneo, salgadinho de pacote ou biscoito salgado
  "5a10a_9" = 30, // Consumo de biscoito recheado, doces ou guloseimas
  "5a10a_10" = 31, // Consumo de Alimentos Ultraprocessados

  // 10 anos a 19 anos:
  "10a19a_1" = 32, // Hábito de realizar no mínimo as três refeições principais do dia
  "10a19a_2" = 33, // Hábito de realizar as refeições assistindo à televisão
  "10a19a_3" = 34, // Consumo de feijão
  "10a19a_4" = 35, // Consumo de fruta
  "10a19a_5" = 36, // Consumo de verduras e legumes
  "10a19a_6" = 37, // Consumo de hambúrguer e/ou embutidos
  "10a19a_7" = 38, // Consumo de bebidas adoçadas
  "10a19a_8" = 39, // Consumo de macarrão instantâneo, salgadinho de pacote ou biscoito salgado
  "10a19a_9" = 40, // Consumo de biscoito recheado, doces ou guloseimas
  "10a19a_10" = 41 // Consumo de Alimentos Ultraprocessados
}

export enum Mode {
  "simplified" = 0,
  "complete" = 1,
  "analytical" = 2,
  "history" = 3,
  "historySimplified" = 4
}

export enum AppMode {
  "compact" = 0,
  "expanded" = 1
}
