import { Fragment, h, JSX } from "preact";
import BoxHighlightNumber from "@components/BoxHighlightNumber";
import formatNumber from "@lib/formatNumber";
import SvgHelpIcon from "@components/SvgHelpIcon";
import { ChildLabelFunction } from "@_types/ChildLabelFunction";
import BoxHistory, { HistoryData } from "@components/BoxHistory";
import ButtonHistory from "@components/ButtonHistory";
import { useState } from "preact/hooks";

type Props = {
  title: string;
  description: string;
  numOfChildren: number;
  populationEstimated: number | undefined;
  percentOfChildren: number | null;
  childLabelFunc: ChildLabelFunction;
  historyData: HistoryData;
  historyDescription: string;
  year: number;
  hasAsterisk: boolean
};

export default function BoxCoverage(props: Props): JSX.Element {
  const {
    title,
    description,
    numOfChildren,
    percentOfChildren,
    populationEstimated,
    childLabelFunc,
    historyData,
    historyDescription,
    year,
    hasAsterisk
  } = props;

  const [historyOpened, setHistoryOpened] = useState<boolean>(false);

  return (
    <div className={"container"}>
      <article className="box-coverage">
        <h1>{title}</h1>
        <div style={"float: right"}>
          <ButtonHistory id={"1"} isOpen={historyOpened} onClick={() => setHistoryOpened(!historyOpened)} />
        </div>
        <BoxHighlightNumber value={percentOfChildren} type="percent" hasAsterisk={hasAsterisk}>
          { (percentOfChildren && populationEstimated) &&
            <Fragment>
              <em>{formatNumber(percentOfChildren, 2)}%{ hasAsterisk ? '*' : ''}</em>{" "}
              representa <em>{formatNumber(numOfChildren, 0)}</em> {childLabelFunc(numOfChildren, false, true)}{" "} pelo SISVAN{" "}
              em uma população de{" "}
              <em>{formatNumber(populationEstimated, 0)}</em> {childLabelFunc(populationEstimated, false, false)}{" "}
              na mesma faixa etária.

              <SvgHelpIcon title={"Taxa de Cobertura"} description={description} />
            </Fragment>
          }

          { !(percentOfChildren && populationEstimated) &&
            <Fragment>
              <strong>{formatNumber(numOfChildren, 0)}</strong> {childLabelFunc(numOfChildren, false, true)}{" "} pelo SISVAN.
              {" "}Não há disponibilidade da taxa de cobertura e da estimativa populacional para filtros por raça/cor entre 2015 e 2021.

              <SvgHelpIcon title={"Taxa de Cobertura"} description={description} />
            </Fragment>
          }
        </BoxHighlightNumber>

        {historyOpened && (
          <BoxHistory
            mode={"simplified"}
            title={title}
            description={historyDescription}
            historyData={historyData}
            childLabelFunction={childLabelFunc}
            year={year}
            isCoverage={true}
          />
        )}
      </article>
    </div>
  );
}
