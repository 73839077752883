import { h, JSX } from "preact";
import { useCallback, useState } from "preact/hooks";
import { Local } from "@_types/Local";
import { PopoverProvider, TooltipProvider } from "@components/Overlay";
import BoxLoader from "@components/BoxLoader";
import SvgDesiderata from "@components/SvgDesiderata";
import useCitiesApi from "@hooks/useCitiesApi";
import brazil from "@data/brazil";
import AppFooter from "@components/AppFooter";
import AppHeader from "@components/AppHeader";
import useLocalDataApi from "@hooks/useLocalDataApi";
import AppMain from "@components/AppMain";
import BoxAlertContainer from "@components/BoxAlertContainer";
import textAlertErrorRequest from "@texts/textAlertErrorRequest";
import HeaderLocalNavigator from "@components/HeaderLocalNavigator";
import AppSuperHeader from "@components/AppSuperHeader";
import Buoyant from "@components/Buoyant";
import Banner from "@components/Banner";
import ModalMobilize, { ModalActiveScreen } from "@components/ModalMobilize";
import Divider from "@components/Divider";
declare const gtag: any;
declare const google: any;

function gtag_mobilize(place: string): void {
  gtag('event', 'desiderata_mobilize');
  gtag('event', `desiderata_mobilize_${place}`)
}

export default function App(): JSX.Element {
  const [localSelected, setLocalSelected] = useState<Local>(brazil);
  const [year, setYear] = useState<number>(2023);
  const [citiesLoading, citiesResult] = useCitiesApi();
  const [localDataLoading, localData, localError] = useLocalDataApi(localSelected);
  const [chartsLoading, setChartsLoading] = useState<boolean>(true);
  const [displayModal, setDisplayModal] = useState<ModalActiveScreen>(null);

  const chartLoadingCallback = useCallback(() => setChartsLoading(false), [setChartsLoading]);
  if (typeof window !== "undefined") {
    google.charts.load("51", { packages: ["corechart"], language: "pt_BR" });
    google.charts.setOnLoadCallback(chartLoadingCallback);
  }

  if (citiesLoading || chartsLoading) {
    return <BoxLoader />;
  }

  return (
    <PopoverProvider>
      <TooltipProvider>
        <AppSuperHeader />
        <header className="text-center container">
          <AppHeader />
        </header>

        <HeaderLocalNavigator cities={citiesResult} localUpdater={setLocalSelected} year={year} yearUpdater={setYear} />

        {localData && localSelected && year && (
          <AppMain local={localSelected} localData={localData} year={year} openModal={() => {gtag_mobilize('botao_excesso_peso'); setDisplayModal(1)}} />
        )}
        {localError && <BoxAlertContainer title={"Erro de conexão"} description={textAlertErrorRequest} />}

        <Divider />

        <Banner onClick={() => {gtag_mobilize('banner'); setDisplayModal(2)}} />
        <AppFooter />

        {localDataLoading && !localError && <BoxLoader />}

        <Buoyant onClick={() => { gtag_mobilize('rodape'); setDisplayModal(1) } } />

        <ModalMobilize
          isOpen={displayModal !== null}
          position={displayModal}
          setPosition={setDisplayModal}
          cities={citiesResult}
          onClose={() => setDisplayModal(null)}
          local={localSelected}
        />
        <SvgDesiderata />
      </TooltipProvider>
    </PopoverProvider>
  );
}
