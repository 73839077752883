import { Fragment, h, JSX } from "preact";
import { Local } from "@_types/Local";
import { MenuItemAge } from "@_types/MenuItems";
import { NutritionFood, NutritionFoodDictionaryItem, NutritionFoodItem } from "@_types/NutritionFood";
import BoxCoverage from "@components/BoxCoverage";
import SectionFoodItem from "@components/SectionFoodItem";
import nutritionFoodDictionaryItems from "@data/nutritionFoodDictionaryItems";
import { Age, NutritionFoodID } from "@_types/Enums";
import getChildLabelFunctionForFoodItems from "@lib/getChildLabelFuncitonForFoodItems";
import getLocalNameFoodItems from "@lib/getLocalNameFoodItems";
import textCoverageExtraInfo from "@texts/textCoverageExtraInfo";
import BoxHistory, { HistoryData } from "@components/BoxHistory";
import { useCallback, useEffect, useState } from "preact/hooks";
import { IndicatorsType } from "@components/SectionFood";
import SvgIcon from "@components/SvgIcon";
import partialYear from "@data/partialYear";

type Props = {
  year: number;
  age: MenuItemAge;
  local: Local;
  nutritionFood?: NutritionFood;
  nutritionFoodHistory: NutritionFood[];
  historyDescription: string;
  indicatorsType: IndicatorsType;
};

let historyIdSelectedDefault: string | null = null;
let lastIndicatorSelected: IndicatorsType | null = null;

export default function SectionFoodContent(props: Props): JSX.Element {
  const { year, age, local, nutritionFood, nutritionFoodHistory, historyDescription, indicatorsType } = props;

  const dictionaryItems = nutritionFoodDictionaryItems.find((item) => item.age === age.value);

  const defaultIndexed = {} as Record<NutritionFoodID, NutritionFoodItem>;
  const indexedNutritionFood = nutritionFood?.items.reduce((p, i) => ({ ...p, [i.id]: i }), defaultIndexed) || [];
  const childLabelFunction = getChildLabelFunctionForFoodItems(age.value);
  const localName = getLocalNameFoodItems(local);
  const estPop: number | undefined = nutritionFood?.estPop;

  const defaultIndexedDictionary = {} as Record<NutritionFoodID, NutritionFoodDictionaryItem>;
  const dictionaryItemsMerged = [...(dictionaryItems?.badItems || []), ...(dictionaryItems?.goodItems || [])];
  const indexedDictionary = dictionaryItemsMerged.reduce((p, i) => ({ ...p, [i.id]: i }), defaultIndexedDictionary);
  const useGoodItems = indicatorsType === "good" || age.value === Age["0m6m"];
  const itemsToDisplay = (useGoodItems ? dictionaryItems?.goodItems : dictionaryItems?.badItems) || [];

  useEffect(() => {
    if (indicatorsType !== lastIndicatorSelected) {
      setHistoryIdSelected(null);
      lastIndicatorSelected = indicatorsType;
    }
  }, [indicatorsType]);

  const [historyIdSelected, setHistoryIdSelected] = useState<string | null>(historyIdSelectedDefault);
  const onClickHistoryButton = useCallback(
    (id: string) => setHistoryIdSelected(id !== historyIdSelected ? id : null),
    [historyIdSelected, setHistoryIdSelected]
  );
  const onCloseClick = useCallback(() => setHistoryIdSelected(null), [setHistoryIdSelected]);
  useEffect(() => {
    if (historyIdSelected) {
      historyIdSelectedDefault = historyIdSelected;
    }
  }, [historyIdSelected]);

  let coverageContent = null;
  let historyContent = null;

  if (historyIdSelected) {
    const selected = parseInt(historyIdSelected, 10) as NutritionFoodID;
    const historyData: HistoryData = nutritionFoodHistory.map((item: NutritionFood) => {
      const foodItem = item.items.find((_item: NutritionFoodItem) => _item.id === selected);
      return {
        year: item.y,
        number: foodItem?.yes || 0,
        percent: foodItem?.p || 0,
        total: foodItem?.total || 0,
        isPartial: item.y === partialYear,
        isCurrent: item.y === year
      };
    });

    const coverageData: HistoryData = nutritionFoodHistory.map((item: NutritionFood) => {
      const foodItem = item.items.find((_item: NutritionFoodItem) => _item.id === selected);
      return {
        year: item.y,
        number: foodItem?.total || 0,
        percent: item.estPop && foodItem ? (foodItem.total / item.estPop) * 100 : undefined,
        total: item.estPop || undefined,
        isCurrent: item.y === year,
        isPartial: item.y === partialYear
      };
    });

    const selectedItem = indexedDictionary[selected];
    if (selectedItem) {
      historyContent = (
        <BoxHistory
          title={`Histórico - ${selectedItem.title}`}
          description={historyDescription}
          historyData={historyData}
          childLabelFunction={childLabelFunction}
          onCloseClick={onCloseClick}
          year={year}
        />
      );

      const foodItem = indexedNutritionFood[selected];
      coverageContent = (
        <BoxCoverage
          title={`Taxa de Cobertura em ${year}  - ${age.text} - ${selectedItem.title}`}
          numOfChildren={foodItem?.total || 0}
          percentOfChildren={estPop && foodItem ? (foodItem.total / estPop) * 100 : null}
          populationEstimated={estPop}
          description={textCoverageExtraInfo}
          childLabelFunc={childLabelFunction}
          historyData={coverageData}
          historyDescription={historyDescription}
          year={year}
          hasAsterisk={false}
        />
      );
    }
  }

  return (
    <Fragment>
      <div className={"container"}>
        <div className="food-consumption flex margin-vertical">
          <article>
            <div className="nutrition-food">
              {itemsToDisplay.map((item) => {
                return (
                  <SectionFoodItem
                    key={item}
                    totalChildren={indexedNutritionFood[item.id]?.total || 0}
                    childLabelFunction={childLabelFunction}
                    localName={localName}
                    itemId={item.id}
                    itemFood={indexedNutritionFood[item.id] || null}
                    dictionary={item}
                    group={indicatorsType}
                    year={year}
                    onClickHistoryButton={onClickHistoryButton}
                    isHistoryOpen={`${item.id}` === historyIdSelected}
                    extraClass={
                      !historyIdSelected || `${item.id}` === historyIdSelected ? "" : "display-block-only-normal"
                    }
                  />
                );
              })}
            </div>
          </article>

          {historyIdSelected && historyContent}
          {historyContent === null && (
            <div className={"mode-history-close"}>
              <span className={"display-block-only-mobile"}>Selecione o indicador acima para ver o histórico</span>
              <SvgIcon icon={"line-chart"} />
              <span className={"display-block-only-normal"}>Selecione o indicador ao lado para ver o histórico</span>
            </div>
          )}
        </div>
      </div>

      {coverageContent}
    </Fragment>
  );
}
