import { h, JSX } from "preact";
import Button from "@components/Dropdown/Button";
import { MenuItem, MenuItems } from "@_types/MenuItems";
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "preact/hooks";
import formatNumber from "@lib/formatNumber";
import { ChildLabelFunction } from "@_types/ChildLabelFunction";
import textNoteAboutSkinDataFrom2022 from "@texts/textNoteAboutSkinDataFrom2022";

declare const google: any;

type Mode = "complete" | "simplified";

export type HistoryData = HistoryDataItem[];
export type HistoryDataItem = {
  year: number;
  number: number;
  percent?: number;
  total?: number;
  isPartial?: boolean;
  isCurrent?: boolean;
};

type Props = {
  year: number;
  title: string;
  description: string;
  childLabelFunction: ChildLabelFunction;
  historyData: HistoryData;
  mode?: Mode;
  onCloseClick?: () => void;
  isCoverage?: boolean;
};

const chartOptionsAbsolute = {
  curveType: "function",
  colors: ["#0083ca"],
  legend: "none",
  chartArea: { width: "90%", height: "90%" },
  axisTitlesPosition: "in",
  lineWidth: 4,
  pointSize: 8,
  hAxis: {
    format: "#",
    gridlines: {
      count: 8
    },
    ticks: [
      { v: 2015, f: "2015" },
      { v: 2016, f: "2016" },
      { v: 2017, f: "2017" },
      { v: 2018, f: "2018" },
      { v: 2019, f: "2019" },
      { v: 2020, f: "2020" },
      { v: 2021, f: "2021" },
      { v: 2022, f: "2022" },
      { v: 2023, f: "2023" },
      { v: 2024, f: "2024*" }
    ]
  },
  vAxis: {
    textPosition: "in",
    minValue: 0,
    viewWindow: {
      min: 0
    }
  },
  annotations: {
    boxStyle: {
      stroke: "#ddd",
      strokeWidth: 1,
      rx: 5,
      ry: 5,
      gradient: {
        color1: "#ffffff",
        color2: "#ffffff",
        x1: "0%",
        y1: "0%",
        x2: "100%",
        y2: "100%",
        useObjectBoundingBoxUnits: true
      }
    },
    textStyle: {
      fontName: "Breve Sans",
      bold: true,
      color: "#444444",
      fontSize: 16
    }
  }
};

const chartOptionsPercent = {
  curveType: "function",
  fontName: "Breve Sans",
  colors: ["#0083ca"],
  legend: "none",
  chartArea: { width: "90%", height: "90%" },
  axisTitlesPosition: "in",
  lineWidth: 4,
  pointSize: 8,
  hAxis: {
    format: "#",
    gridlines: {
      count: 8
    },
    ticks: [
      { v: 2015, f: "2015" },
      { v: 2016, f: "2016" },
      { v: 2017, f: "2017" },
      { v: 2018, f: "2018" },
      { v: 2019, f: "2019" },
      { v: 2020, f: "2020" },
      { v: 2021, f: "2021" },
      { v: 2022, f: "2022" },
      { v: 2023, f: "2023" },
      { v: 2024, f: "2024*" }
    ]
  },
  vAxis: {
    minValue: 0,
    maxValue: 1,
    viewWindow: {
      min: 0
    },
    format: "#.#%",
    textPosition: "in"
  },

  annotations: {
    boxStyle: {
      stroke: "#ddd",
      strokeWidth: 1,
      rx: 5,
      ry: 5,
      gradient: {
        color1: "#ffffff",
        color2: "#ffffff",
        x1: "0%",
        y1: "0%",
        x2: "100%",
        y2: "100%",
        useObjectBoundingBoxUnits: true
      }
    },
    textStyle: {
      fontName: "Breve Sans",
      bold: true,
      color: "#444444",
      fontSize: 16
    },
    alwaysOutside: true
  }
};

export default function BoxHistory({
  year,
  title,
  description,
  childLabelFunction,
  historyData,
  onCloseClick = () => 0,
  mode = "complete",
  isCoverage = false
}: Props): JSX.Element {
  let hasPercent = false;
  historyData.forEach((item: HistoryDataItem) => {
    if (item.percent) {
      hasPercent = true;
    }
  });

  const labelNumber = isCoverage ? `Total de acompanhamentos` : `Número de ${childLabelFunction(0)}`;
  const labelPercent = isCoverage ? `Taxa de cobertura` : "Percentual";

  const options = useMemo(() => {
    const _options: MenuItems = [];
    _options.push({ value: 1, text: labelNumber });
    if (hasPercent) {
      _options.push({ value: 2, text: labelPercent });
    }
    _options.push({ value: 3, text: "Tabela" });

    return _options;
  }, [labelNumber, hasPercent, labelPercent]);

  const [selected, setSelected] = useState<MenuItem>(hasPercent ? options[1] : options[0]);

  useEffect(() => {
    if (hasPercent === false && selected.value === 2) {
      setSelected(options[0]);
    }
  }, [hasPercent, selected, setSelected, options]);

  const [imageUriChartNumber, setImageUriChartNumber] = useState<string>();
  const [imageUriChartPercent, setImageUriChartPercent] = useState<string>();

  const divRefChartNumber = useRef<HTMLDivElement>(null);
  const divRefChartPercent = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    setImageUriChartNumber(undefined);
    if (!divRefChartNumber.current || typeof window === "undefined") {
      return;
    }

    const arrayData = [];
    arrayData.push(["Ano", labelNumber, { role: "annotation" }, { type: "string", role: "style" }]);

    historyData.forEach((item: HistoryDataItem) => {
      arrayData.push([
        item.year,
        item.number,
        formatNumber(item.number, 0),
        item.year === year ? "point { size: 10; shape-type: circle; fill-color: #0082ca; }" : ""
      ]);
    });

    if (historyData.length > 0) {
      const data = google.visualization.arrayToDataTable(arrayData);
      const yearFormatter = new google.visualization.NumberFormat({ pattern: "#" });
      yearFormatter.format(data, 0);

      const chart = new google.visualization.LineChart(divRefChartNumber.current);

      google.visualization.events.addListener(chart, "ready", () => {
        setImageUriChartNumber(chart.getImageURI());
      });

      if(isCoverage) {
        chart.draw(data, { ...chartOptionsAbsolute, backgroundColor: '#e6e6e6' });
      } else {
        chart.draw(data, chartOptionsAbsolute);
      }

    }
  }, [year, historyData, labelNumber]);

  useLayoutEffect(() => {
    setImageUriChartPercent(undefined);
    if ((!divRefChartPercent.current && !hasPercent) || typeof window === "undefined") {
      return;
    }

    const arrayData = [];
    arrayData.push(["Ano", labelPercent, { role: "annotation" }, { type: "string", role: "style" }]);

    historyData.forEach((item: HistoryDataItem) => {
      if (item.percent !== undefined) {
        arrayData.push([
          item.year,
          item.percent / 100,
          `${formatNumber(item.percent, 1)}%`,
          item.year === year ? "point { size: 10; shape-type: circle; fill-color: #0082ca; }" : ""
        ]);
      }
    });

    if (historyData.length > 0) {
      const data = google.visualization.arrayToDataTable(arrayData);
      const yearFormatter = new google.visualization.NumberFormat({ pattern: "#" });
      yearFormatter.format(data, 0);
      const percentFormatter = new google.visualization.NumberFormat({ pattern: "#.#%" });
      percentFormatter.format(data, 1);

      const chart = new google.visualization.LineChart(divRefChartPercent.current);

      google.visualization.events.addListener(chart, "ready", () => {
        setImageUriChartPercent(chart.getImageURI());
      });

      if(isCoverage) {
        chart.draw(data, { ...chartOptionsPercent, backgroundColor: '#e6e6e6' });
      } else {
        chart.draw(data, chartOptionsPercent);
      }
    }
  }, [hasPercent, historyData, year, isCoverage, labelPercent]);

  const onCloseCallback = useCallback(() => onCloseClick(), [onCloseClick]);

  if (mode === "complete") {
    return (
      <div className="box-history box-bordered margin-vertical">
        <h1>
          <span>Gráfico {title}</span>
          <a
            href={"#"}
            onClick={(e) => {
              e.preventDefault();
              onCloseCallback();
            }}
          >
            x
          </a>
        </h1>
        <h2>{description}</h2>
        <div className="button-group">
          {options.map((o: MenuItem) => {
            return (
              <Button
                key={o.value}
                label={o.text}
                itemSelected={selected.value === o.value ? o : null}
                toggleOpen={() => setSelected(o)}
              />
            );
          })}
        </div>
        <div style={{ width: "100%" }} className={selected.value === 1 ? "fade-block-in" : "fade-block-out"}>
          {historyData.length > 0 && <div style={{ height: "360px", width: "100%" }} ref={divRefChartNumber} />}
          {historyData.length === 0 && <NoData />}
          {imageUriChartNumber !== undefined && <LinkChartDownload uri={imageUriChartNumber} name={"numero"} />}
        </div>
        <div style={{ width: "100%" }} className={selected.value === 2 ? "fade-block-in" : "fade-block-out"}>
          {historyData.length > 0 && <div style={{ height: "360px", width: "100%" }} ref={divRefChartPercent} />}
          {historyData.length === 0 && <NoData />}
          {imageUriChartPercent !== undefined && <LinkChartDownload uri={imageUriChartPercent} name={"percentual"} />}
        </div>
        <div style={{ width: "100%" }} className={selected.value === 3 ? "fade-block-in" : "fade-block-out"}>
          {historyData.length === 0 && <NoData />}
          {historyData.length > 0 && (
            <TableContent historyData={historyData} childLabelFunction={childLabelFunction} isCoverage={isCoverage} />
          )}
        </div>
        <div>
          <p className={"notes-text"}>
            <span>
              * Os dados de 2024 são parciais e foram obtidos no SISVAN em 01/07/2024.
            </span>
            <br />
            {isCoverage && (
              <span>
                * Entre 2015 e 2021 os dados de população são obtidos através de estimativas do DATASUS.
                A partir de 2022 os dados de população são oficiais do Censo Demográfico 2022.<br />
                * {textNoteAboutSkinDataFrom2022}
              </span>
            )}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="box-history margin-vertical">
      <div className="button-group">
        {options.map((o: MenuItem) => {
          return (
            <Button
              key={o.value}
              label={o.text}
              itemSelected={selected.value === o.value ? o : null}
              toggleOpen={() => setSelected(o)}
            />
          );
        })}
      </div>
      <div style={{ width: "100%" }} className={selected.value === 1 ? "fade-block-in" : "fade-block-out"}>
        {historyData.length > 0 && <div style={{ height: "360px", width: "100%" }} ref={divRefChartNumber} />}
        {historyData.length === 0 && <NoData />}
        {imageUriChartNumber !== undefined && (
          <LinkChartDownload uri={imageUriChartNumber} name={"taxa-cobertura-numero"} />
        )}
      </div>
      <div style={{ width: "100%" }} className={selected.value === 2 ? "fade-block-in" : "fade-block-out"}>
        {historyData.length > 0 && <div style={{ height: "360px", width: "100%" }} ref={divRefChartPercent} />}
        {historyData.length === 0 && <NoData />}
        {imageUriChartPercent !== undefined && (
          <LinkChartDownload uri={imageUriChartPercent} name={"taxa-cobertura-percentual"} />
        )}
      </div>
      <div style={{ width: "100%" }} className={selected.value === 3 ? "fade-block-in" : "fade-block-out"}>
        {historyData.length === 0 && <NoData />}
        {historyData.length > 0 && (
          <TableContent historyData={historyData} childLabelFunction={childLabelFunction} isCoverage={isCoverage} />
        )}
      </div>
      <div>
        <p className={"notes-text"}>
          <span>
            * Os dados de 2024 são parciais e foram obtidos no SISVAN em 01/07/2024.
          </span>
          <br />
          {isCoverage && (
            <span>
              * Entre 2015 e 2021 os dados de população são obtidos através de estimativas do DATASUS.
              A partir de 2022 os dados de população são oficiais do Censo Demográfico 2022.<br />
              * {textNoteAboutSkinDataFrom2022}
            </span>
          )}
        </p>
      </div>
    </div>
  );
}

type TableContentProps = {
  historyData: HistoryData;
  childLabelFunction: ChildLabelFunction;
  isCoverage: boolean;
};

function TableContent(props: TableContentProps): JSX.Element {
  const { historyData, childLabelFunction, isCoverage } = props;
  return (
    <table className={"box-history-table"}>
      <tr>
        <th>Ano</th>
        <th>
          {isCoverage ? (
            "Total de acompanhamentos"
          ) : (
            <span>
              Número <span className="hoss">de {childLabelFunction(0)}</span>
            </span>
          )}
        </th>
        <th>
          Percentual <span className="hoss">{isCoverage ? "da população" : "do total"}</span>
        </th>
        <th>{isCoverage ? "População na faixa etária" : "Total de acompanhamentos"}</th>
      </tr>

      {historyData.map((item: HistoryDataItem, index) => (
        <TableLineOfData key={index} childLabelFunction={childLabelFunction} isCoverage={isCoverage} {...item} />
      ))}
    </table>
  );
}

type TableLineOfDataProps = HistoryDataItem & {
  childLabelFunction: ChildLabelFunction;
  isCoverage: boolean;
};

function TableLineOfData(props: TableLineOfDataProps): JSX.Element {
  const { year, number, percent, total, isPartial = false, isCurrent = false, childLabelFunction, isCoverage } = props;

  const n = formatNumber(number, 0);
  const t = total !== undefined ? formatNumber(total, 0) : "";
  const p = percent !== undefined ? formatNumber(percent, 1) : "";

  let title;
  if (isCoverage) {
    title =
      percent !== undefined && total !== undefined
        ? `${n} ${childLabelFunction(
            number
          )} acompanhadas (${p}%) de uma poplação estimada em ${t} ${childLabelFunction(total)} da mesma faixa etária.`
        : `${n} ${childLabelFunction(number, false, true)}`;
  } else {
    title =
      percent !== undefined && total !== undefined
        ? `${n} ${childLabelFunction(number)} (${p}%) de um total de ${t} acompanhamentos.`
        : `${n} ${childLabelFunction(number, false, true)}`;
  }

  return (
    <tr className={isCurrent ? "active" : ""} title={title}>
      <td>
        <strong>{year}</strong> {isPartial ? <small>(parcial*)</small> : isCurrent ? <small>(selecionado)</small> : ""}
      </td>
      <td>
        <strong>{n}</strong>
      </td>
      <td>
        {percent !== undefined && <strong>{p}%</strong>}
        {percent === undefined && <small>--</small>}
      </td>
      <td>
        {total !== undefined && (
          <strong>
            {t}
            {isCoverage && [2022, 2023, 2024].includes(year) && "*"}
          </strong>
        )}
        {total === undefined && <small>--</small>}
      </td>
    </tr>
  );
}

type LinkChartDownloadProps = {
  uri: string;
  name: string;
};

function LinkChartDownload(props: LinkChartDownloadProps): JSX.Element {
  const { uri, name } = props;
  return (
    <a
      href={uri}
      className={"box-history-link-download"}
      title={"Baixe este gráfico em imagem"}
      download={`panorama-obesidade-${name}`}
    >
      Baixar imagem do gráfico
    </a>
  );
}

function NoData(): JSX.Element {
  return (
    <div style={{ height: "auto", width: "100%", fontStyle: "italic" }}>
      Nenhum acompanhamento registrado entre 2015 e 2023.
    </div>
  );
}
